body {
  font-family: Arial, sans-serif;
  margin: 0;
  padding: 0;
  background-color: #f4f4f4;
}

h1 {
  text-align: center;
  color: #333;
}

form {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 20px;
}

input[type="text"] {
  margin: 5px;
  padding: 10px;
  width: 300px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

button {
  padding: 10px 20px;
  background-color: #28a745;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

button:hover {
  background-color: #218838;
}

label {
  margin: 10px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
}

input[type="range"] {
  width: 300px;
}

ul {
  list-style: none;
  padding: 0;
  text-align: center;
}

li {
  background: white;
  margin: 10px;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

